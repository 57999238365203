<template>
  <div class="why-page">
    <h1 class="text-center">
      Why should I join?
    </h1>
    <h2 class="text-center">
      You’re missing out on great content, events and offers. Here’s a little taste of what’s going on in {{ brand.name }}
    </h2>
    <div
      class="container"
    >
      <el-row :gutter="30">
        <el-col
          v-for="feedItem in feedItems"
          :key="`${feedItem.feed_key}-${feedItem.id}`"
          :xl="6"
          :lg="8"
          :md="12"
          :sm="12"
          :xs="24"
          class="el-col-newsitem"
        >
          <router-link
            v-if="feedItem.feed_key === 'Article'"
            :to="{ name: 'public-article', params: { id: feedItem.id } }"
          >
            <article-card
              :media="feedItem.mediaFile.is_image? feedItem.mediaFile.medium_thumb: feedItem.mediaFile.video_thumb"
              :is-image="feedItem.mediaFile.is_image"
              :title="feedItem.title"
              :author-name="feedItem.author"
              :author-avatar="feedItem.author_avatar_url"
              :date="feedItem.published_at"
              :featured="feedItem.is_featured"
            />
          </router-link>
          <router-link
            v-if="feedItem.feed_key === 'Event'"
            :to="{ name: 'public-event', params: { id: feedItem.id } }"
          >
            <event-card
              :title="feedItem.title"
              :featured="feedItem.is_featured"
              :venue="feedItem.address_line_1"
              :start-date="feedItem.start_date"
              :end-date="feedItem.end_date"
              :start-time="feedItem.start_time"
              :end-time="feedItem.end_time"
              :same-day="feedItem.same_day"
            />
          </router-link>
        </el-col>
      </el-row>
    </div>
    <transition name="fade">
      <div
        v-if="loading"
        v-loading="loading"
        class="container"
        style="min-height: 90px"
      ></div>
    </transition>
  </div>
</template>

<script>
import ArticleCard from '@/modules/feed/components/article-card'
import EventCard from '@/modules/feed/components/event-card'
import feedApi from '@/modules/feed/services/feed.api'

export default {
  components: {
    ArticleCard,
    EventCard,
  },

  data() {
    return {
      feedItems: [],
      loading: true,
      page: 1,
      totalPages: 0,
      bottom: false,
    }
  },
  watch: {
    bottom(bottom) {
      if (bottom) {
        this.page++;
        this.getFeedItems();
        if(this.page <= this.totalPages) {
          this.loading = true;
        }
      }
    }
  },
  updated() {
    this.loading = false;
  },
  created() {
    window.addEventListener('scroll', () => {
      this.bottom = this.bottomVisible()
    })

    if(this.totalPages < this.page) {
      this.getFeedItems();
    }
  },
  methods: {
    bottomVisible() {
      const scrollY = window.scrollY;
      const visible = document.documentElement.clientHeight;
      const pageHeight = document.documentElement.scrollHeight;
      const bottomOfPage = visible + scrollY >= pageHeight;
      return bottomOfPage || pageHeight < visible;
    },
    getFeedItems() {
      this.loading = true
      feedApi
        .feed(true, {
          is_pinned: false,
          group_id: 1,
          per_page: 12,
          page: this.page
        })
        .then((response) => {
          this.totalPages = response.data.meta.pagination.total_pages
          this.feedItems.push(...response.data.data)
          this.loading = false
        });
    }
  },
}
</script>

<style lang="scss" scoped>
    .why-page {
        h1 {
            @media (max-width: 768px) {
                font-size: 30px;
            }
        }
        h2 {
            width: 35%;
            line-height: 21px;
            font-weight: initial;
            margin: 0 auto 50px auto;
            color: $body-text-color;
            @media (max-width: 767px) {
                width: 55%;
            }
            @media (max-width: 425px) {
                width: 85%;
            }
        }
        .container {
            min-height: 320px;
        }
        .el-col-newsitem {
          margin-bottom: 30px;
        }
    }
    .fade-enter-active {
        transition: ease-in opacity 2s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
        opacity: 0;
    }
</style>
